import $ from 'jquery';
import {TweenMax, Power2, TimelineLite,TimelineMax} from "gsap";
import ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
(function($){
	var controller = new ScrollMagic.Controller();
//meniu
	if($('#header').length) {
		var menu_top=$('.header_top');
		var menu=$('#header');
		function desktopmenu() {
			var headerScene = new ScrollMagic.Scene({
				triggerElement: '#wrap-header',
				triggerHook:0,
				offset:'35px',
			})	
			.addTo(controller)
		//	.addIndicators()
			;
			headerScene.on("enter",function() {
				TweenMax.to(menu,0,{css:{'position':'fixed','z-index':1035,'left':0,'right':0,'top':0,'border-bottom':'1px solid #e7e7e7'}});
				TweenMax.to(menu_top,0,{css:{'display':'none'}});
				console.log("Enter..");
			});
			headerScene.on("leave",function() {
				TweenMax.to(menu,0,{css:{'position':'absolute','left':0,'right':0,'top':35,'border-bottom':'0px'}},0);
				TweenMax.to(menu_top,0,{css:{'display':'block'}});
				console.log("Leave...");
			});
			
			
		}
		
		$(document).ready(() => {
			if(window.innerWidth >= 991) {
				desktopmenu();
			} else {
				mobilemenu();
			}
		})
		
	}
//effect vertical line
	
	if($('.band2').length) {
		var band2Scene = new ScrollMagic.Scene({
			triggerElement:'.band2',
			triggerHook: 1,
			duration: "100%"
		})
		.addTo(controller);
		
		band2Scene.on("start",function () {
			var i1=$('#i1');
			var i2=$('#i2');
			var i3=$('#i3');
			var i4=$('#i4');
			var svg=$('.svg1');
			var tl1=new TimelineMax({
				onUpdateParams:["{self}"],
				onUpdate:function(tl){
					var map=parseInt($("#idlinie1").data("max"));
					var tlp = parseInt(tl.progress() * map);
					$("#idlinie1").html(ttt(tlp));
					
				},
			
			});
			tl1.set(svg,{rotation:-180},0);
			tl1.to(i1,1.2,{attr:{'height':'200px'}},0);
			
			var tl2=new TimelineMax({
				delay:0,
				onUpdateParams:["{self}"],
				onUpdate:function(tl){
					var map=parseInt($("#idlinie2").data("max"));
					var tlp = parseInt(tl.progress() * map);
					$("#idlinie2").html(ttt(tlp));
					
				},
			
			});
			tl2.set(svg,{rotation:-180},0);
			tl2.to(i2,1.2,{attr:{'height':'100px'}})
			
			var tl3=new TimelineMax({
				delay:0,
				onUpdateParams:["{self}"],
				onUpdate:function(tl){
					var map=parseInt($("#idlinie3").data("max"));
					var tlp = parseInt(tl.progress() * map);
					$("#idlinie3").html(ttt(tlp));
					
				},
			
			});
			tl3.set(svg,{rotation:-180},0);
			tl3.to(i3,1.2,{attr:{'height':'190px'}});
			
			var tl4=new TimelineMax({
				delay:0,
				onUpdateParams:["{self}"],
				onUpdate:function(tl){
					var map=parseInt($("#idlinie4").data("max"));
					var tlp = parseInt(tl.progress() * map);
					$("#idlinie4").html(ttt(tlp));
					
				},
			
			});
			tl4.set(svg,{rotation:-180},0);
			tl4.to(i4,1.2,{attr:{'height':'150px'}});
			
		})
	}
	
//effect circle

	if($('#parallax1').length) {
		console.log("parallax");

		var parallaxTl = new TimelineMax();
		parallaxTl
			.from($('#bgparallax1'), 1, {y: '-70%', ease:Power0.easeNone}, 0)
			;
		var slideParallaxScene = new ScrollMagic.Scene({
			triggerElement:'#parallax1',
			triggerHook: 1,
			duration: "100%"
		})
		.setTween(parallaxTl,)
		.addTo(controller)
	//	.addIndicators() 
		;
	
		slideParallaxScene.on('start',
			function() {
				var svg=$('.svg');
				var tl1=new TimelineMax({
					onUpdateParams:["{self}"],
					onUpdate:function(tl){
						var map=parseInt($("#ind1").data("max"));
						var tlp = parseInt(tl.progress() * map);
						$("#ind1").html(ttt(tlp));
						
					},
				});
				tl1.set(svg,{rotation:-90},0);
				tl1.set($("#circle1"),{autoAlpha:0},0);
				tl1.fromTo($("#circle1"),1.2,{drawSVG:'0% 0%',autoAlpha:1},{drawSVG:'100%,100%'},0);
				
				var tl2=new TimelineMax({
					delay:.5,
					onUpdateParams:["{self}"],
					onUpdate:function(tl){
						var map=parseInt($("#ind2").data("max"));
						var tlp = parseInt(tl.progress() * map);
						$("#ind2").html(ttt(tlp));
						
					},
				});
				tl2.set(svg,{rotation:-90},0);
				tl2.set($("#circle2"),{autoAlpha:0},0);
				tl2.fromTo($("#circle2"),1.2,{drawSVG:'0% 0%',autoAlpha:1},{drawSVG:'100%,100%'},0);
				
				var tl3=new TimelineMax({
					delay:1,
					onUpdateParams:["{self}"],
					onUpdate:function(tl){
						var map=parseInt($("#ind3").data("max"));
						var tlp = parseInt(tl.progress() * map);
						$("#ind3").html(ttt(tlp));
						
					},
				});
				tl3.set(svg,{rotation:-90},0);
				tl3.set($("#circle3"),{autoAlpha:0},0);
				tl3.fromTo($("#circle3"),1.2,{drawSVG:'0% 0%',autoAlpha:1},{drawSVG:'100%,100%'},0);
				
				var tl4=new TimelineMax({
					delay:1.5,
					onUpdateParams:["{self}"],
					onUpdate:function(tl){
						var map=parseInt($("#ind4").data("max"));
						var tlp = parseInt(tl.progress() * map);
						$("#ind4").html(ttt(tlp));
						
					},
				});
				tl4.set(svg,{rotation:-90},0);
				tl4.set($("#circle4"),{autoAlpha:0},0);
				tl4.fromTo($("#circle4"),1.2,{drawSVG:'0% 0%',autoAlpha:1},{drawSVG:'100%,100%'},0);
				
				
			})
	}
	
	
})(jQuery);
function ttt(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}