import Swiper from 'swiper';

var mySwiper = new Swiper('.swiper-container', {
	/*	navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},*/
		slidesPerView:4,
		slidesPerColumn:1,
		observer: true,
		observeParents: true,
		setWrapperSize: true,
		spaceBetween:30,
      // init: false,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			1600: {
			slidesPerView: 4,
			spaceBetween: 30,
			},
			1400: {
			slidesPerView: 3,
			spaceBetween: 30,
			},
			1200: {
			slidesPerView: 3,
			spaceBetween: 30,
			},
			992: {
			slidesPerView: 3,
			spaceBetween: 30,
			},
			768: {
			slidesPerView: 2,
			spaceBetween: 30,
			},
			576: {
			slidesPerView: 1,
			spaceBetween: 30,
			}
		}
		
});
	
var testimonialSwiper = new Swiper('.swiper-container-testimonial', {
	//	navigation: {
		//	nextEl: '.swiper-button-next',
		//	prevEl: '.swiper-button-prev',
		//	nextEl: '.fa-angle-left',
		//	prevEl: '.fa-angle-right',
	//	},
		slidesPerView:4,
		slidesPerColumn:1,
		observer: true,
		observeParents: true,
		setWrapperSize: true,
		spaceBetween:30,
      // init: false,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			1600: {
			slidesPerView: 4,
			spaceBetween: 30,
			},
			1400: {
			slidesPerView: 3,
			spaceBetween: 30,
			},
			1200: {
			slidesPerView: 3,
			spaceBetween: 30,
			},
			992: {
			slidesPerView: 3,
			spaceBetween: 30,
			},
			768: {
			slidesPerView: 2,
			spaceBetween: 30,
			},
			576: {
			slidesPerView: 1,
			spaceBetween: 30,
			}
		}
		
});