jQuery('document').ready(function($) {
	$(document).on('click', '.showModalButton', function(){
		var ajax_href=$(this).data('value');
		var ajax_title=$(this).data('title');
		var tip=$(this).data('tip');
		$('#mmodal').find('#modal-title').empty();
		$('#mmodal').find('.modal-body').empty();
		if ($('#mmodal').data('bs.modal').isShown) {
			$('#mmodal').find('#modal-title').html(ajax_title);
			$('#mmodal').find('.modal-body').load(ajax_href,function(){
				$('#mmodal').find("input[name='CreditForm[tip]'").val(tip);
			});
	
		} else {
			$('#mmodal').find('#modal-title').html(ajax_title);
			$('#mmodal').modal('show').find('.modal-body').load(ajax_href,function(){
				$('#mmodal').find("input[name='CreditForm[tip]'").val(tip);
			});
			
		}
	});
	
	$('body').on('click', '#ltrimite', function(e) {
//		console.log('trimite');
		$('#frezervare').submit();
		
    });
	
	$('body').on('beforeSubmit', 'form#frezervare', function () {
		var form = $(this);
		if (form.find('.has-error').length) {
			return false;
		}
		// submit form
		$.ajax({
			url: form.attr('action'),
			type: 'post',
			data: form.serialize(),
		 	dataType: 'json',
			beforeSend: function() {
				$('.rotate').css('display','block');
			},
			success: function (response) {
				$('#modal-title').empty();
				$('#modal-title').html(response['titlu']);
				$('#modcredit').empty();
				$('#modcredit').html(response['mesaj']);
				console.log(response);
			},
			error:function(x,y,z) {
				console.log(x);
				console.log(y);
				console.log(z);
			},
		}).done(function() {
			$('.rotate').css('display','none');
			setTimeout(function(){
				$('#mmodal').modal('hide')
				}, 3000);
		});
		return false;
	});
	
    
    $('body').on('click', '#lreset', function(e) {;
        console.log("reset");
	    $('#frezervare').trigger('reset');
    });
    
    
})