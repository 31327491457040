import '../lib/DrawSVGPlugin.min.js';
import $ from 'jquery';
import {TweenMax, Power2, TimelineLite,TimelineMax} from "gsap";
(function($){
	var $slider=$('.slider');
	var $blackopacity=$('.blackopacity');
	var tdelay=7;
	var tfade=1.2;
	var tblack=1;
	$(".greensock-slider").height($(".greensock-slider").width() * 560/1590);
	TweenMax.set($slider.filter(":gt(0)"), {autoAlpha:0});
	TweenMax.set($slider.filter(":eq(0)"), {autoAlpha:1});
	//TweenMax.set($blackopacity, {autoAlpha:1});
	var tl= new TimelineMax({repeat:-1,repeatDelay:0});
	console.log($slider);
	for(tl.data=0;tl.data<$slider.length;tl.data++) {
		if (tl.data==$slider.length-1) {
		//	tl.to($slider[tl.data].children[0],tblack,{autoAlpha:1,ease:Power0.easeInOut},tl.data*tdelay+tfade);
		//	tl.to($slider[tl.data].children[0],tblack,{autoAlpha:0,ease:Power0.easeInOut},((tl.data+1)*tdelay));
			tl.to($slider[0],tfade,{autoAlpha:1},(tl.data+1)*tdelay);
			tl.to($slider[tl.data],tfade,{autoAlpha:0},(tl.data+1)*tdelay);
		} else {
		//	tl.to($slider[tl.data].children[0],tblack,{autoAlpha:1,ease:Power0.easeInOut},tl.data*tdelay+tfade);
		//	tl.to($slider[tl.data].children[0],tblack,{autoAlpha:0,ease:Power0.easeInOut},((tl.data+1)*tdelay));
			tl.to($slider[tl.data+1],tfade,{autoAlpha:1},(tl.data+1)*tdelay);
			tl.to($slider[tl.data],tfade,{autoAlpha:0},(tl.data+1)*tdelay);
		}
		//console.log($slider[tl.data].attributes["data-width"]);
		/*
		tl.set(
			$slider[tl.data].children[1],
			{css:{'top':$slider[tl.data].dataset["top"],'left':$slider[tl.data].dataset["left"],'width':$slider[tl.data].dataset["width"],'opacity':'1'}},
			tl.data
				
		);			
		var arr=[].slice.call($slider[tl.data].children[1].children);
		for(j=0;j<arr.length;j++) {	
			if(j==0) {
				w1=arr[j].clientWidth+2;
				tl.set(
					arr[j],
					{css:{'white-space': 'nowrap','float':'left','width':w1}},
					(tl.data+0)*tdelay
				
				);	
				tl.fromTo(
					arr[j],
					1,
					{css:{'text-indent':-w1,'opacity':1,'width':w1}},
					{css:{'text-indent':0,'opacity':1,'width':w1},ease:Power2.easeOut},
					tl.data*tdelay + tfade
				)
						
			}
			if(j==1) {
				tl.set(
					arr[j],
					{css:{'white-space': 'nowrap','float':'right','width':w1,'opacity':0}},
					(tl.data+0)*tdelay
				
				);	
				tl.fromTo(
					arr[j],
					1.8,
					{css:{'font-weight':800,'opacity':1,'width':0,'margin-right':0}},
					{css:{'font-weight':800,'opacity':1,'width':w1+10,'margin-right':0},ease:Power2.easeOut},
					tl.data*tdelay + tfade
				)
			}
				
			if(j==2) {
				w1=arr[j].clientWidth;
				tl.set(
					arr[j],
					{css:{'clear':'both','white-space': 'nowrap','opacity':'0','margin-left':'0px'}},
					(tl.data+0)*tdelay
				
				);	
				tl.fromTo(
					arr[j],
					.18,
					{autoAlpha:0,top:'50px'},
					{autoAlpha:1,top:'0px',ease:Power2.easeOut},
					tl.data*tdelay + tfade + .2
				)
			}
			if(j==3) {
				tl.set(
					arr[j],
					{css:{'opacity':'0','margin-left':'0px','font-size':'16px'}},
					(tl.data+0)*tdelay
				
				);	
				tl.fromTo(
					arr[j],
					1,
					{autoAlpha:0,top:'50px'},
					{autoAlpha:1,top:'0px',ease:Power2.easeOut},
					tl.data*tdelay + tfade + .4
				)
			}
			if(j==4) {
				tl.set(
						arr[j],
						{css:{'background-color':'rgba(255,255,255,1)','padding':'5px 15px','opacity':'0','margin-left':'0px','font-size':'20px','border':'1px solid #86898b','border-radius':'5px','color':'#86898b'}},
						(tl.data+0)*tdelay
				
				);	
				tl.fromTo(
					arr[j],
					1.3,
					{autoAlpha:0,top:'50px'},
					{autoAlpha:1,top:'0px',ease:Power2.easeOut},
					tl.data*tdelay + tfade + .6
				)
			}	
			
		} */
		
		
		$('#p'+tl.data).click(function() {
			//$(this).data(id);
			console.log($(this));
			tl.data=$(this).data('id');
			console.log(tl.data);
			tl.seek(tl.data*tdelay+.6);
		})
			
	}
		
		
	$('#bnext').click(function() {
		    console.log("before"+tl.data);
			if(tl.data>=4){tl.data=0;}
			else{tl.data=tl.data+1;}
			console.log(tl.data);
			tl.seek(tl.data*tdelay+.6);
		
	});
	$('#bprev').click(function() {
			console.log("before"+tl.data);
			if(tl.data<=0){tl.data=0;}
			else{tl.data=tl.data-1;}
			console.log(tl.data);
			tl.seek(tl.data*tdelay+.6);
		
	});	
	
	$( window ).resize(function() {
		$(".greensock-slider").height($(".greensock-slider").width() * 560/1590);
	});
	
	
	
	
})(jQuery);